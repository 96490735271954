import { FC, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { ModuleScheduleItem, News, NewsCategory, Pagination } from '@common/clients/api';
import { ItemsPerPage } from '@common/types';
import { fetchNews } from '@web/handlers/fetchNews';
import { MatchTicker } from '@web/molecules/MatchTicker';
import { NewsList } from '@web/molecules/NewsList';
import { NotificationBarHolder } from '@web/molecules/NotificationBar';
import { TagIntroBlock, TagIntroBlockProps, TagIntroBlockType } from '@web/molecules/TagIntroBlock';
import { MetaHead } from '@web/organisms/MetaHead';
import { Sidebar } from '@web/organisms/Sidebar';
import { DefaultServerSideProps, Route } from '@web/routing';

export interface State {
    currentPage: number;
    pagination?: Pagination;
    newsItems: News[];
    newsCategory?: NewsCategory;
    promoItems: ModuleScheduleItem[];
}

export interface Props extends DefaultServerSideProps {
    state: State;
}

export const CategoryNews: FC<Props> = ({ state: initialState, contextData }) => {
    const [state, setState] = useState<State>(initialState);
    const { newsItems, promoItems, pagination, newsCategory } = state;

    if (!newsCategory) throw new Error("newsCategory can't be undefined");

    const onReadMoreClick = async () => {
        const newState = {
            ...state,
            currentPage: state.currentPage + 1,
        };
        const data = await fetchNews({
            contextData: contextData,
            isClientSide: true,
            newsCatID: state.newsCategory?.id,
            perPage: contextData.route === Route.CategoryNews ? ItemsPerPage.HUGE : ItemsPerPage.SMALL,
            currentPage: newState.currentPage,
        });
        if (data?.data) {
            newState.newsItems = [...state.newsItems, ...data.data];
            newState.pagination = data?.pagination;
            setState(newState);
        }
    };

    const __meta = useTranslation('meta').t;
    let title = __meta(`newslist:latest-title.${newsCategory.slug}`);
    if (title === `newslist:latest-title.${newsCategory.slug}` || title === newsCategory.slug) {
        title = __meta('newslist:latest-title.common', { category: newsCategory.name });
    }

    let description = __meta(`category-news.${newsCategory.slug}`);
    if (description === `category-news.${newsCategory.slug}` || description === newsCategory.slug) {
        description = __meta(`category-news.description-short`, { tag: newsCategory.name });
    }

    const tagIntroBlockData: TagIntroBlockProps = {
        type: TagIntroBlockType.COMMON,
        superTitle: __meta(`category-news.the-latest`) || '',
        title: newsCategory.name || '',
        description: description,
        thumbnail: '',
        backgroundImage: newsCategory.name || '',
        isMiniView: true,
        hasTagOutro: false,
        __translate: __meta,
    };

    return (
        <>
            <MetaHead title={title} description={description} />
            <MatchTicker />
            <div className="main-content">
                <TagIntroBlock {...tagIntroBlockData} />
                {newsItems.length ? (
                    <NewsList
                        title={<h3>{title}</h3>}
                        items={newsItems}
                        pagination={pagination}
                        onReadMoreClick={onReadMoreClick}
                        showIconTabs={true}
                        promoItems={promoItems}
                    />
                ) : null}
            </div>
            <Sidebar />
            <NotificationBarHolder />
        </>
    );
};
